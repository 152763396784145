// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// redux
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { HeaderProvider } from './contexts/HeaderContext';
import { AuthProvider } from './contexts/FirebaseContext';
import { StripeProvider } from './contexts/StripeContext';

//
import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ReduxProvider store={store}>
    <AuthProvider>
      <StripeProvider>
        <HelmetProvider>
          <PersistGate loading={null} persistor={persistor}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <HeaderProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </HeaderProvider>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </PersistGate>
        </HelmetProvider>
      </StripeProvider>
    </AuthProvider>
  </ReduxProvider>
);

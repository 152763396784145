// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';
import { UnsavedPromptProvider } from './contexts/UnsavedPromptContext';
import { PromptProvider } from './contexts/PromptContext';
import { ProgramClientProvider } from './contexts/ProgramClientContext';
import { StripeModalProvider } from './contexts/StripeModalContext';
import { ClientSubscriptionProvider } from 'src/contexts/ClientSubscriptionContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <PromptProvider>
              <UnsavedPromptProvider>
                <StripeModalProvider>
                  <ClientSubscriptionProvider>
                    <ProgramClientProvider>
                      <ProgressBarStyle />
                      <ScrollToTop />
                      <Router />
                    </ProgramClientProvider>
                  </ClientSubscriptionProvider>
                </StripeModalProvider>
              </UnsavedPromptProvider>
            </PromptProvider>
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import { CircularProgress, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import { useDispatch, useSelector } from 'src/redux/store';
import usePrompt from 'src/hooks/usePrompt';
import { deleteProgram } from 'src/redux/slices/programs';
import { useSnackbar } from 'notistack';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { duplicateProgram, getProgramDuplicateStatus } from 'src/redux/slices/program/program';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
//

// ----------------------------------------------------------------------

type Props = {
  programId: string;
  assignProgramClients?: VoidFunction;
  unassignClientFromProgram?: VoidFunction;
};

export default function ProgramMenu({
  programId,
  assignProgramClients,
  unassignClientFromProgram,
}: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { openPrompt } = usePrompt();
  const navigate = useNavigate();
  const duplicateStatus = useSelector(getProgramDuplicateStatus);
  const duplicating = duplicateStatus === FETCH_STATUS_TYPES_ENUM.LOADING;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(null);
  };

  const handleEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    navigate(PATH_DASHBOARD.program.root(programId));
  };

  const handleDuplicate = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    await dispatch(duplicateProgram(programId));
    enqueueSnackbar('Program has been duplicated.', { variant: 'success' });
    setOpen(null);
  };

  const handleAssignClients = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (assignProgramClients) {
      assignProgramClients();
    }
    setOpen(null);
  };

  const handleUnassign = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (unassignClientFromProgram) {
      unassignClientFromProgram();
      enqueueSnackbar('Program has been unassigned.', { variant: 'success' });
    }
    setOpen(null);
  };

  const handleDelete = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    openPrompt({
      title: 'Delete?',
      message:
        "Are you sure you want to delete this program? Any clients or users will no longer be able to access this program once it's removed. This action cannot be undone.",
      confirmLoadingMessage: 'Deleting...',
      confirmText: 'Delete',
      cancelText: 'Cancel',
      confirm: async () => {
        await dispatch(deleteProgram({ programId }));
        enqueueSnackbar('Program has been deleted.', { variant: 'success' });
        setOpen(null);
      },
      cancel: async () => {
        setOpen(null);
      },
    });
  };

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ position: 'absolute', top: 10, right: 10, zIndex: 8 }}>
        <Iconify icon={'eva:more-vertical-fill'} color={'common.white'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="top-right"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {assignProgramClients !== undefined && (
          <MenuItem onClick={handleAssignClients}>
            <Iconify icon={'ic:baseline-person-add'} />
            Assign Clients
          </MenuItem>
        )}
        {unassignClientFromProgram !== undefined && (
          <MenuItem onClick={handleUnassign}>
            <Iconify icon={'ic:baseline-person-remove'} />
            Unassign
          </MenuItem>
        )}

        <MenuItem onClick={handleEdit}>
          <Iconify icon={'eva:edit-fill'} />
          Edit
        </MenuItem>

        {duplicating ? (
          <Stack sx={{ py: 0.5, px: 1 }} spacing={2} direction="row">
            <CircularProgress size={18} />
            <Typography sx={{ fontSize: 14 }}>Duplicating</Typography>
          </Stack>
        ) : (
          <MenuItem onClick={handleDuplicate}>
            <Iconify icon={'ic:outline-content-copy'} />
            Duplicate
          </MenuItem>
        )}

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}

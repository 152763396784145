import { ReactNode, createContext, useState } from 'react';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
// stripe

// ----------------------------------------------------------------------
// Status of the client subscription
type ClientSubscriptionContextProps = {
  status: FETCH_STATUS_TYPES_ENUM;
  setStatus: (status: FETCH_STATUS_TYPES_ENUM) => void;
};

const initialState: ClientSubscriptionContextProps = {
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  setStatus: () => {},
};

const ClientSubscriptionContext = createContext(initialState);

type ClientSubscriptionProviderProps = {
  children: ReactNode;
};

function ClientSubscriptionProvider({ children }: ClientSubscriptionProviderProps) {
  const [status, setStatus] = useState<FETCH_STATUS_TYPES_ENUM>(FETCH_STATUS_TYPES_ENUM.IDLE);

  return (
    <ClientSubscriptionContext.Provider value={{ status, setStatus }}>
      {children}
    </ClientSubscriptionContext.Provider>
  );
}

export { ClientSubscriptionProvider, ClientSubscriptionContext };

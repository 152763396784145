import { ReactNode, createContext } from 'react';
// stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// ----------------------------------------------------------------------

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const StripeContext = createContext(null);

type StripeProviderProps = {
  children: ReactNode;
};

function StripeProvider({ children }: StripeProviderProps) {
  return (
    <StripeContext.Provider value={null}>
      <Elements stripe={stripePromise}>{children}</Elements>
    </StripeContext.Provider>
  );
}

export { StripeProvider, StripeContext };

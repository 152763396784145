import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { getUserId } from 'src/redux/slices/user';
import { Alert } from '@mui/material';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import {
  fetchStripeInvoices,
  getStripeInvoicesFetchError,
  getStripeInvoicesFetchStatus,
  selectAllStripeInvoices,
} from 'src/redux/slices/stripeInvoices';

// ----------------------------------------------------------------------

export default function useStripeInvoices() {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const invoices = useSelector(selectAllStripeInvoices);
  const fetchStatus = useSelector(getStripeInvoicesFetchStatus);
  const fetchError = useSelector(getStripeInvoicesFetchError);

  useEffect(() => {
    if (userId && fetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(fetchStripeInvoices());
    }
  }, [fetchStatus, userId, dispatch]);

  const ErrorAlert = () => (!!fetchError ? <Alert severity="error">{fetchError}</Alert> : null);

  return {
    invoices,
    fetchStatus,
    fetchError,
    ErrorAlert,
  };
}

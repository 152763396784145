import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Program, ProgramFilter as ProgramFilterType } from 'src/@types/program';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { RootState } from '../store';
import {
  collection,
  query,
  where,
  // orderBy,
  limit,
  startAt as firestoreStartAt,
  getDocs,
  QueryConstraint,
  updateDoc,
  doc,
  orderBy,
  writeBatch,
} from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { duplicateProgram, saveProgram, saveProgramDetails } from './program/program';
import useTypesense from 'src/hooks/useTypesense';
import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { ProgramCleanable } from 'src/@types/typesense';
import convertTypesenseDataDates from 'src/utils/convertTypesenseDataDates';
import { closeCopyToModalAction } from './program/copyToModal';
import { ProgramUser } from 'src/@types/firebase';
import { handleLoadProgramWeeks } from './program/functions/load';

const FETCH_LIMIT = 30;

export const fetchPrograms = createAsyncThunk<{
  programs: Program[];
  lastVisibleDoc: any;
  page: number;
}>('programs/fetchPrograms', async (_, { getState }) => {
  const state = getState() as RootState;
  const userId = state.user.id;
  const { filters, sortBy } = state.programs;
  let { lastVisibleDoc, page } = state.programs;

  const creatorIds = [userId];

  const programs: Program[] = [];

  if (!filters.searchPhrase) {
    let firebaseOrderBy = orderBy('title', 'asc');
    if (sortBy === 'titleDesc') {
      firebaseOrderBy = orderBy('title', 'desc');
    }

    const queryConstraints: QueryConstraint[] = [
      firebaseOrderBy,
      where('creatorIds', 'array-contains', userId),
      // firebaseOrderBy,
      limit(FETCH_LIMIT),
    ];

    if (lastVisibleDoc) {
      queryConstraints.push(firestoreStartAt(lastVisibleDoc));
    }

    const q = query(collection(DB, 'programs'), ...queryConstraints);

    const querySnapshot = await getDocs(q);
    lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      const item = { ...doc.data(), id: doc.id } as Program;
      programs.push(item);
    });
  } else {
    // increment page
    page += 1;

    const searchParameters: SearchParams = {
      q: filters.searchPhrase ? filters.searchPhrase : '*',
      query_by: 'title, description',
      query_by_weights: '4, 2',
      page: page,
      per_page: FETCH_LIMIT,
    };

    let filterBy = null;
    // Creator filters
    if (creatorIds.length) {
      const creatorFilter = `creatorIds:= [${creatorIds.join()}]`;
      filterBy = filterBy ? filterBy + ' && ' + creatorFilter : creatorFilter;
    }

    if (filterBy) {
      searchParameters.filter_by = filterBy;
    }

    // Typesense search
    const client = useTypesense();

    const response = await client
      .collections<ProgramCleanable>('programs')
      .documents()
      .search(searchParameters);

    if (response?.hits && response.hits.length !== 0) {
      programs.push(
        ...response.hits.map((hit) => {
          const item = hit.document;
          // Remove Typesense only fields re: ExerciseTypesense
          delete item.dateCreatedUnix;
          delete item.lastUpdatedUnix;
          convertTypesenseDataDates(item);

          return item;
        })
      );
    }
  }

  return { programs, lastVisibleDoc, page };
});

// Delete a program and all associated data
export const deleteProgram = createAsyncThunk<
  string,
  {
    programId: string;
  }
>('programs/deleteProgram', async ({ programId }) => {
  const batch = writeBatch(DB);

  // Delete all subcollections (programWeeks, workouts)
  // Get all programWeeks and workouts
  const { programWeeks, workouts } = await handleLoadProgramWeeks({ programId });

  if (!programWeeks || !workouts) {
    throw new Error('Program weeks or workouts are undefined');
  }

  // Delete program in firestore
  const programRef = doc(DB, 'programs', programId);

  batch.delete(programRef);

  // Delete programWeeks
  programWeeks.forEach((programWeek) => {
    const programWeekRef = doc(DB, 'programs', programId, 'programWeeks', programWeek.id);
    batch.delete(programWeekRef);
  });

  // Delete workouts
  workouts.forEach((workout) => {
    const workoutRef = doc(
      DB,
      'programs',
      programId,
      'programWeeks',
      workout.programWeekId,
      'workouts',
      workout.id
    );
    batch.delete(workoutRef);
  });

  await batch.commit();

  return programId;
});

// assign users to program
export const assignProgramUsers = createAsyncThunk<
  Program,
  {
    program: Program;
    newProgramUsers: ProgramUser[];
  }
>('programs/assignProgramUsers', async ({ program, newProgramUsers }) => {
  const userIds = newProgramUsers.map((client) => client.id);
  const users = newProgramUsers;
  // Update program users
  const programUpdate: Program = {
    ...program,
    userIds,
    users,
  };

  // Update program in firestore
  const { id, ...programData } = programUpdate;
  const docRef = doc(DB, 'programs', id);
  await updateDoc(docRef, { ...programData });

  return programUpdate;
});

// Unassign user from program
export const unassignProgramUser = createAsyncThunk<
  Program,
  {
    program: Program;
    removedClientId: string;
  }
>('programs/unassignProgramUser', async ({ program, removedClientId }) => {
  // Remove user from program
  const userIds = program.userIds.filter((userId) => userId !== removedClientId);
  const users = program.users.filter((user) => user.id !== removedClientId);
  const programUpdate: Program = {
    ...program,
    userIds,
    users,
  };

  // Update program in firestore
  const { id, ...programData } = programUpdate;
  const docRef = doc(DB, 'programs', id);
  await updateDoc(docRef, { ...programData });

  return programUpdate;
});

const programsAdapter = createEntityAdapter<Program>({
  // Sort by title
  // sortComparer: (a: Program, b: Program) => a.title.localeCompare(b.title),
});

const initialState = programsAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  page: 0,
  lastVisibleDoc: null,
  sortBy: 'titleAsc',
  filters: {
    searchPhrase: '',
  },
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null; page: number; lastVisibleDoc: any | null; sortBy: string | null; filters: ProgramFilterType });

export const slice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    reset: () => initialState,
    //  SORT & FILTER PRODUCTS
    programsSortBy(state, action) {
      state.sortBy = action.payload;
    },
    //  SORT & FILTER PRODUCTS
    startSearch: (state) => {
      programsAdapter.removeAll(state);
      state.page = initialState.page;
      state.lastVisibleDoc = initialState.lastVisibleDoc;
      state.status = FETCH_STATUS_TYPES_ENUM.SEARCHING;
      state.error = null;
    },
    endSearch: (state, action) => {
      state.filters.searchPhrase = action.payload;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
  },
  extraReducers(builder) {
    builder
      // Reset
      .addCase(closeCopyToModalAction, () => initialState)

      // Internal
      .addCase(fetchPrograms.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchPrograms.fulfilled, (state, action) => {
        // state.status = 'succeeded';
        // Upsert all the added exercise metrics
        const { lastVisibleDoc, page, programs: items } = action.payload;

        if (items.length !== 0) {
          programsAdapter.upsertMany(state, items);
          state.lastVisibleDoc = lastVisibleDoc;
          state.page = page;
          // Change status to succeeded
          if (items.length < FETCH_LIMIT) {
            state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
          } else {
            state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
          }
        } else {
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        }
      })
      .addCase(fetchPrograms.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      .addCase(deleteProgram.fulfilled, (state, action) => {
        const itemId = action.payload;
        programsAdapter.removeOne(state, itemId);
      })

      .addCase(duplicateProgram.fulfilled, (state, action) => {
        const program = action.payload;
        // Insert at the index of the item that was duplicated
        const programs = Object.values(state.entities).filter(
          (program): program is Program => !!program
        );
        const index = programs.findIndex((item) => item.title === program.title);
        // Insert at the index of the item that was duplicated
        programs.splice(index, 0, program);

        programsAdapter.setAll(state, programs);
      })

      .addCase(assignProgramUsers.fulfilled, (state, action) => {
        const item = action.payload;
        programsAdapter.upsertOne(state, item);
        // Only upload if the item already exists
        // if (state.entities[item.id]) {

        // }
      })
      .addCase(unassignProgramUser.fulfilled, (state, action) => {
        const item = action.payload;
        programsAdapter.upsertOne(state, item);
        // Only upload if the item already exists
        // if (state.entities[item.id]) {
        //   programsAdapter.upsertOne(state, item);
        // }
      })

      // External
      .addCase(saveProgram.fulfilled, (state, action) => {
        const item = action.payload;
        programsAdapter.upsertOne(state, item);
        // Only upload if the item already exists
        // if (state.entities[item.id]) {

        // }
      })
      .addCase(saveProgramDetails.fulfilled, (state, action) => {
        const item = action.payload;
        programsAdapter.upsertOne(state, item);
        // Only upload if the item already exists
        // if (state.entities[item.id]) {

        // }
      });
  },
});

export const { reset, programsSortBy, startSearch, endSearch } = slice.actions;

export default slice.reducer;

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllPrograms,
  selectById: selectProgramById,
  // Pass in a selector that returns the posts slice of state
} = programsAdapter.getSelectors((state: RootState) => state.programs);

export const getProgramsFetchStatus = (state: RootState) => state.programs.status;
export const getProgramsFetchError = (state: RootState) => state.programs.error;
export const getProgramsFilters = (state: RootState) => state.programs.filters;
export const getProgramsSortBy = (state: RootState) => state.programs.sortBy;

// redux
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
  Dictionary,
  createAction,
} from '@reduxjs/toolkit';
// types
import { Measurement_WithID, MeasurementLog, MeasurementLog_WithID } from 'src/@types/firebase';
import { MeasurementHistoryItem } from 'src/@types/measurementHistory';
// functions
import { renderGraphValues } from './functions';
import { FETCH_STATUS_TYPES_ENUM, GRAPH_DATE_RANGE_ENUM } from 'src/@types/enums';
import { RootState } from 'src/redux/store';
import { resetClientProfileAction } from '../clientProfile';
import { where, query, collection, getDocs, orderBy } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';

const DEFAULT_MEASUREMENT_ID = 'DfqsrFQBGi04aHWAPA7I';

type FetchDataResponse = {
  items: MeasurementLog_WithID[];
};

const measurementLogDataAdapter = createEntityAdapter<MeasurementLog_WithID>({
  // Sort by index
  // sortComparer: (a, b) => a.index - b.index,
});

const initialState = measurementLogDataAdapter.getInitialState({
  // filter: {
  //   activeMeasurementIds: [],
  //   activeFilters: {},
  // },
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
} as {
  // filter: {
  //   activeMeasurementIds: string[];
  //   activeFilters: {
  //     [key: string]: {
  //       id: string;
  //       type: GRAPH_FILTER_TYPES_ENUM;
  //       min: number;
  //       max: number;
  //     };
  //   };
  // };
  status: FETCH_STATUS_TYPES_ENUM;
  error: string | null;
});

export const fetchMeasurementLogData = createAsyncThunk<
  FetchDataResponse,
  { userId: string; dateRange?: GRAPH_DATE_RANGE_ENUM }
>('measurementLogData/fetchMeasurementLogData', async ({ userId, dateRange }) => {
  const items: MeasurementLog_WithID[] = [];

  const queries = [
    where('userId', '==', userId),
    where('measurement.id', '==', DEFAULT_MEASUREMENT_ID),
    orderBy('dateCreated', 'desc'),
  ];

  if (dateRange !== undefined) {
    const dateRangeDate = new Date(new Date().getTime() - dateRange * 24 * 60 * 60 * 1000);
    queries.push(where('dateCreated', '>=', dateRangeDate));
  }

  // Fetch UserExerciseMetricValueLogs from the last month (30 days) for the given exerciseId from firbase
  const q = query(collection(DB, 'measurementLogs'), ...queries);

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const { id } = doc;
    const data = doc.data() as MeasurementLog;
    convertFirebaseDataDates(data);

    const item = {
      ...data,
      id,
    } as MeasurementLog_WithID;

    items.push(item);
  });

  return { items };
});

export const resetMeasurementLogAction = createAction('measurementLogData/resetMeasurementLogData');

export const slice = createSlice({
  name: 'measurementLogData',
  initialState,
  reducers: {
    // Filters
    // addFilter: (state, action: PayloadAction<AddFilterPayload>) => {
    //   const {id, type, min, max} = action.payload;
    //   const filter = {...state.filter};
    //   filter.activeMeasurementIds.push(id);
    //   filter.activeFilters[id] = {id, type, min, max};
    //   state.filter = filter;
    // },
    // removeFilter: (state, action: PayloadAction<string>) => {
    //   const filter = {...state.filter};
    //   filter.activeMeasurementIds = filter.activeMeasurementIds.filter(
    //     id => id !== action.payload,
    //   );
    //   delete filter.activeFilters[action.payload];
    //   state.filter = filter;
    // },
    // Reset
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      // Reset
      .addCase(resetMeasurementLogAction, () => initialState)
      // Internal
      .addCase(fetchMeasurementLogData.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchMeasurementLogData.fulfilled, (state, action) => {
        // Upsert all the added exercise metrics
        const { items } = action.payload;
        if (items.length !== 0) {
          measurementLogDataAdapter.upsertMany(state, items);

          // Set Filters
          // const measurementsIds = [...new Set(items.map((item) => item.measurementId))];
          // state.filter.activeMeasurementIds = measurementsIds;

          // Change status
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        } else {
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        }
      })
      .addCase(fetchMeasurementLogData.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // External
      .addCase(resetClientProfileAction, () => initialState);
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllMeasurementLogData } = measurementLogDataAdapter.getSelectors(
  (state: RootState) => state.measurementLogData
);

// ----------------------------------------------------------------------

export const getMeasurementLogDataFetchStatus = (state: RootState) =>
  state.measurementLogData.status;
// export const getMeasurementLogDataFilter = (state: RootState) => state.measurementLogData.filter;
export const getMeasurements = (state: RootState): Dictionary<Measurement_WithID> =>
  state.measurements.entities;

// ----------------------------------------------------------------------

export const selectAllMeasurementGraphData = createSelector(
  [
    selectAllMeasurementLogData,
    // getMeasurementLogDataFilter,
    getMeasurements,
  ],
  (
    data,
    // filter,
    measurements
  ) => {
    // Only get active exercise metrics
    // const activeData = data.filter(
    //   (item): item is MeasurementLog =>
    //     !!item &&
    //     filter.activeExerciseMetricIds.includes(item.exerciseMetricId),
    // );

    // Only get data that is numeric
    const numericData = data.filter(
      (item): item is MeasurementLog_WithID => !!item && !!item?.numericValue
    );

    // Format data for graph
    const measurement = measurements[data[0]?.measurement.id];

    if (!measurement) {
      return [];
    }

    const { data: formattedData } = renderGraphValues(numericData, measurement);

    // Filter data
    const filteredData = formattedData;
    // const filteredData = formattedData.filter((item) => {
    //   const min = filter.activeFilters[measurement.id]?.min;
    //   const max = filter.activeFilters[measurement.id]?.max;
    //   const y = item.y;
    //   if (min !== undefined && max !== undefined && y !== undefined) {
    //     return y >= min && y <= max;
    //   }
    //   return true;
    // });

    const metricName = measurement.name;
    const metricData = filteredData;

    const graphData = [
      {
        name: metricName,
        data: [
          {
            name: metricName,
            data: metricData,
          },
        ],
      },
    ];

    return graphData;
  }
);

// ----------------------------------------------------------------------

// export const selectAllMeasurementLegendData = createSelector(
//   [selectAllMeasurementLogData, getMeasurements],
//   (data, measurements) => {
//     // Only get data that is numeric
//     const numericData = data.filter(
//       (item): item is MeasurementLog => !!item && !!item?.numericValue,
//     );

//     const measurement = measurements[numericData[0]?.measurementId];

//     if (!measurement) {
//       return [];
//     }

//     const {data: metricData} = renderGraphValues(numericData, measurement);

//     // Find max and min
//     const maxY = _.maxBy(metricData, 'y')?.y;
//     const minY = _.minBy(metricData, 'y')?.y;

//     const legendItems =
//       minY !== undefined && maxY !== undefined
//         ? [
//             {
//               id: measurement.id,
//               color: measurement.color,
//               name: measurement.name,
//               unit: measurement.unitOfMeasurement.abbreviation,
//               min: minY,
//               max: maxY,
//             },
//           ]
//         : [];

//     return legendItems;
//   },
// );

// // ----------------------------------------------------------------------

// export const selectTopAverageBottom = createSelector(
//   [selectAllMeasurementGraphData],
//   items =>
//     items.reduce((acc, item) => {
//       // Sort by date
//       // item.data.sort(
//       //   (a, b) => new Date(a.x).valueOf() - new Date(b.x).valueOf(),
//       // );
//       const topItem = _.maxBy(item.data, 'y');
//       const bottomItem = _.minBy(item.data, 'y');
//       // Round to 2 decimal places
//       const averageItem = _.meanBy(item.data, 'y').toFixed(2);

//       if (
//         topItem !== undefined &&
//         bottomItem !== undefined &&
//         averageItem !== undefined
//       ) {
//         const top = {
//           id: item.id,
//           name: 'High',
//           value: topItem.y.toString(),
//           unit: item.unit,
//           color: '#1890FF',
//           dateCreated: moment(topItem.x).format('MMM D, YYYY'),
//           otherMetrics: [],
//         };
//         const average = {
//           id: item.id,
//           name: 'Average',
//           value: averageItem.toString(),
//           unit: item.unit,
//           color: '#1ec8d4',
//           dateCreated: '',
//           otherMetrics: [],
//         };
//         const bottom = {
//           id: item.id,
//           name: 'Low',
//           value: bottomItem.y.toString(),
//           unit: item.unit,
//           color: '#3366FF',
//           dateCreated: moment(bottomItem.x).format('MMM D, YYYY'),
//           otherMetrics: [],
//         };

//         acc.push(top, average, bottom);
//       }
//       return acc;
//     }, [] as TopMetricResult[]),
// );

// // ----------------------------------------------------------------------

// export const selectAllSliderItems = createSelector(
//   [selectAllMeasurementLogData, getMeasurementLogDataFilter, getMeasurements],
//   (items, filter, measurements) =>
//     items
//       // Only include items that have an active filter
//       // .filter(
//       //   item =>
//       //     filter.activeExerciseMetricIds.includes(item.exerciseMetricId) &&
//       //     item?.numericValue,
//       // )
//       .reduce((acc, item) => {
//         // If slider already exists for this metric, skip
//         if (acc.find(sliderItem => sliderItem.id === item.measurementId)) {
//           return acc;
//         }

//         const measurement = measurements[item.measurementId];
//         if (!measurement) {
//           return acc;
//         }

//         const {data: metricData} = renderGraphValues(items, measurement);
//         // const metricData = items.filter(
//         //   data =>
//         //     data?.numericValue &&
//         //     data.exerciseMetricId === item.exerciseMetricId,
//         // );
//         // Get unique values and sort from smallest to largest
//         const uniqueValues = _.uniqBy(metricData, 'y').map(d => d.y);
//         uniqueValues.sort((a, b) => a - b);

//         const min = _.minBy(metricData, 'y')?.y;
//         const max = _.maxBy(metricData, 'y')?.y;
//         if (uniqueValues.length > 1 && measurement && min && max) {
//           const sliderItem: ExerciseDataSliderItem = {
//             id: item.measurementId,
//             color: measurement.color,
//             name: measurement.name,
//             unit: '',
//             min,
//             max,
//             values: uniqueValues,
//           };
//           acc.push(sliderItem);
//         }
//         return acc;
//       }, [] as ExerciseDataSliderItem[]),
// );

// ----------------------------------------------------------------------

export const selectAllMeasurementHistoryData = createSelector(
  [selectAllMeasurementLogData],
  (data) => {
    const items: MeasurementHistoryItem[] = [];

    /*
    type MeasurementLog = {
    id: string;
    userId: string;
    measurementLogId: string;
    dateCreated: number;
    dateCreatedMoment: string;
    lastUpdated: number;
    lastUpdatedMoment: string;
    notes: string;
    value: string;
    numericValue?: number | undefined;
    measurementId: string;
    measurementName: string;
}
    */
    data.forEach((item) => {
      const historyItem: MeasurementHistoryItem = {
        id: item.id,
        dateCreated: item.dateCreated,
        value: item.value,
        notes: item.notes,
      };

      items.push(historyItem);
    });

    // Filter out items that don't have a dateCreated
    // Used to filter out some old dirty data e.g. Lau Pang's data
    const filteredItems = items.filter((item) => !!item?.dateCreated?.getTime);

    return filteredItems.sort((a, b) => b.dateCreated.getTime() - a.dateCreated.getTime());
    // return {
    //   listData,
    //   sections,
    // };
  }
);

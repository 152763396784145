// import { paramCase } from 'change-case';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Stack } from '@mui/material';
// @types
import { Program } from 'src/@types/program';
// components
// import Label from 'src/components/Label';
import Image from '../../../components/Image';
import { ProgramUsers } from '.';
import ProgramMenu from './ProgramMenu';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getUserId } from 'src/redux/slices/user';
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  top: 0,
  zIndex: 8,
  content: "''",
  width: '100%',
  height: '100%',
  position: 'absolute',
  opacity: 0.3,
}));

// ----------------------------------------------------------------------

type Props = {
  program: Program;
  assignProgramClients?: () => void;
  unassignClientFromProgram?: () => void;
};

export default function ProgramCard({
  program,
  assignProgramClients,
  unassignClientFromProgram,
}: Props) {
  const navigate = useNavigate();
  const userId = useSelector(getUserId);

  const { id, title, imageUrl, users, creatorIds } = program;
  const editable = userId && creatorIds.includes(userId);

  const handleClick = () => {
    if (editable) {
      navigate(PATH_DASHBOARD.program.root(id));
    }
  };

  const linkTo = PATH_DASHBOARD.program.root(id);
  return (
    <Card onClick={handleClick} sx={{ cursor: editable ? 'pointer' : 'default' }}>
      <Box sx={{ position: 'relative' }}>
        <Image alt={title} src={imageUrl} ratio="16/9" />
        <OverlayStyle />
        {editable && (
          <ProgramMenu
            programId={id}
            assignProgramClients={assignProgramClients}
            unassignClientFromProgram={unassignClientFromProgram}
          />
        )}
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link variant="subtitle2" to={linkTo} color="inherit" noWrap component={RouterLink}>
          {title}
        </Link>

        {!!users?.length && editable && <ProgramUsers programId={id} programUsers={users} />}
      </Stack>
    </Card>
  );
}

import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit';
import { FUNCTIONS } from 'src/contexts/FirebaseContext';
import { RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { httpsCallable } from 'firebase/functions';
import { StripeInvoice } from 'src/@types/invoice';

const stripeInvoiceAdapter = createEntityAdapter<StripeInvoice>({
  // Sort by index
  // sortComparer: (a, b) => a.index - b.index,
});

const initialState = stripeInvoiceAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null });

export const fetchStripeInvoices = createAsyncThunk<StripeInvoice[]>(
  'stripeInvoices/fetchStripeInvoices',
  async () => {
    const getInvoices = httpsCallable<undefined, { invoices: StripeInvoice[] }>(
      FUNCTIONS,
      'events-stripe-getInvoices'
    );
    const { data } = await getInvoices();
    const { invoices } = data;

    return invoices.map((invoice, index) => ({
      ...invoice,
      id: invoice.id ? invoice.id : `draft-${index}`,
    }));
  }
);

export const slice = createSlice({
  name: 'stripeInvoices',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      // Reset case
      .addCase(fetchStripeInvoices.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchStripeInvoices.fulfilled, (state, action) => {
        // Upsert all the added exercise metrics
        const items = action.payload;
        if (items.length !== 0) {
          stripeInvoiceAdapter.upsertMany(state, items);
          // Change status
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        } else {
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        }
      })
      .addCase(fetchStripeInvoices.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllStripeInvoices } = stripeInvoiceAdapter.getSelectors(
  (state: RootState) => state.stripeInvoices
);

export const getStripeInvoicesFetchStatus = (state: RootState) => state.stripeInvoices.status;
export const getStripeInvoicesFetchError = (state: RootState) => state.stripeInvoices.error;

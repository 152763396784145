// @mui
import { Box, Grid, Card, Button, Typography, Stack } from '@mui/material';
//
// import AccountBillingAddressBook from './AccountBillingAddressBook';
import AccountBillingPaymentMethod from './AccountBillingPaymentMethod';
import AccountBillingInvoiceHistory from './AccountBillingInvoiceHistory';
import { StripeClientSubscription, UserStripePaymentMethod } from 'src/@types/firebase';
import { httpsCallable } from 'firebase/functions';
import { FUNCTIONS } from 'src/contexts/FirebaseContext';
import usePrompt from 'src/hooks/usePrompt';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

type Props = {
  paymentMethod?: UserStripePaymentMethod;
  clientSubscription?: StripeClientSubscription;
};

export default function AccountBilling({ paymentMethod, clientSubscription }: Props) {
  const { openPrompt } = usePrompt();
  const { enqueueSnackbar } = useSnackbar();

  const hasSubscription = clientSubscription?.upgraded;
  const plan = hasSubscription ? 'Flexi plan' : 'No current plan';

  // const handleStripeQuantityChange = async () => {
  //   const setStripeQuantity = httpsCallable(FUNCTIONS, 'events-stripe-setUsage');
  //   // Convert to integer
  //   const qauntityNum = parseInt(quantity);

  //   // If quantity is a number
  //   if (!isNaN(qauntityNum)) {
  //     const result = await setStripeQuantity({ quantity: qauntityNum });
  //     console.log('Qauntity Result:', result);
  //   }
  // };
  const handleCancelSubscription = async () => {
    const cancelSubscription = httpsCallable(FUNCTIONS, 'events-stripe-cancelClientSubscription');

    // Open a confirmation dialog
    openPrompt({
      title: 'Cancel Flexi Plan',
      message: 'Are you sure you want to cancel your plan?',
      confirmLoadingMessage: 'Cancelling...',
      confirmText: 'Yes',
      cancelText: 'No',
      confirm: async () => {
        await cancelSubscription();
        enqueueSnackbar('Subscription cancelled', { variant: 'success' });
      },
      cancel: async () => {},
    });
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          <Card sx={{ p: 3 }}>
            <Typography
              variant="overline"
              sx={{ mb: 3, display: 'block', color: 'text.secondary' }}
            >
              Your Plan
            </Typography>
            <Typography variant="h4">{plan}</Typography>
            <Box
              sx={{
                mt: { xs: 2, sm: 0 },
                position: { sm: 'absolute' },
                top: { sm: 24 },
                right: { sm: 24 },
              }}
            >
              {hasSubscription && (
                <Button
                  onClick={handleCancelSubscription}
                  size="small"
                  color="inherit"
                  variant="outlined"
                  sx={{ mr: 1 }}
                >
                  Cancel plan
                </Button>
              )}
            </Box>
          </Card>

          <AccountBillingPaymentMethod paymentMethod={paymentMethod} />

          {/* <AccountBillingAddressBook addressBook={addressBook} /> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <AccountBillingInvoiceHistory />
      </Grid>
    </Grid>
  );
}

import {
  createEntityAdapter,
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  addDoc,
  collection,
  doc,
  increment,
  onSnapshot,
  query,
  Unsubscribe,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { CoachClient, CoachClient_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import {
  CLIENT_ACTIVE_STATUS_TYPES,
  CLIENT_INACTIVE_STATUS_TYPES,
  CLIENT_STATUS_ENUM,
  FETCH_STATUS_TYPES_ENUM,
} from 'src/@types/enums';
import { AppDispatch, RootState } from '../store';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';

const clientsAdapter = createEntityAdapter<CoachClient_WithID>({
  // Sort by title
  sortComparer: (a: CoachClient_WithID, b: CoachClient_WithID) =>
    a.client.firstName.localeCompare(b.client.firstName),
});

const initialState = clientsAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  saveStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  saveError: null,
  updateStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  updateError: null,
  listener: null,
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null; saveStatus: FETCH_STATUS_TYPES_ENUM; saveError: string | null; updateStatus: FETCH_STATUS_TYPES_ENUM; updateError: string | null; listener: null | Unsubscribe });

// Save client
export const saveClient = createAsyncThunk<
  CoachClient_WithID,
  {
    coachClient: CoachClient;
  }
>('clients/saveClient', async ({ coachClient }, { getState }) => {
  const state = getState() as RootState;

  // Check if email already in clients
  const existingCoachClients = Object.values(state.clients.entities).filter(
    (client) => !!client && client.client.email === coachClient.client.email
  );
  if (existingCoachClients.length > 0) {
    throw new Error('You already have a client with that email address');
  }

  // const ref = await addDoc(collection(DB, 'coachClients'), coachClient);

  // batch.update(doc(DB, 'users', coachClient.coach.id), {
  //   // decrement activeClients
  //   activeClients: increment(1),
  // });

  // Add coach client
  const ref = await addDoc(collection(DB, 'coachClients'), coachClient);

  // update coach activeClients
  await updateDoc(doc(DB, 'users', coachClient.coach.id), {
    activeClients: increment(1),
  });

  return { ...coachClient, id: ref.id };
});

// Delete client
export const deleteClient = createAsyncThunk<
  null,
  {
    coachClientId: string;
  }
>('clients/deleteClient', async ({ coachClientId }, { getState }) => {
  const state = getState() as RootState;
  const coachClient = state.clients.entities[coachClientId];

  if (!coachClient) {
    throw new Error('Client not found');
  }

  const batch = writeBatch(DB);

  // Delete client
  batch.delete(doc(DB, 'coachClients', coachClientId));

  // If client counted as an active client, decrement coach activeClients
  if (CLIENT_ACTIVE_STATUS_TYPES.includes(coachClient.status)) {
    batch.update(doc(DB, 'users', coachClient.coach.id), {
      // decrement activeClients
      activeClients: increment(-1),
    });
  }

  await batch.commit();

  return null;
});

// Update client
export const updateClient = createAsyncThunk<
  null,
  {
    coachClientId: string;
    updates: Partial<CoachClient>;
  }
>('clients/updateClient', async ({ coachClientId, updates }, { getState }) => {
  const state = getState() as RootState;
  const batch = writeBatch(DB);

  // If status is changing, check if it's changing from active to inactive or vice versa
  if (updates?.status) {
    const coachClient = state.clients.entities[coachClientId];
    if (!coachClient) {
      throw new Error('Client not found');
    }

    // If client is changing from active to inactive, decrement coach activeClients
    if (
      CLIENT_ACTIVE_STATUS_TYPES.includes(coachClient.status) &&
      CLIENT_INACTIVE_STATUS_TYPES.includes(updates.status)
    ) {
      batch.update(doc(DB, 'users', coachClient.coach.id), {
        // decrement activeClients
        activeClients: increment(-1),
      });
    }

    // If client is changing from inactive to active, increment coach activeClients
    if (
      CLIENT_INACTIVE_STATUS_TYPES.includes(coachClient.status) &&
      CLIENT_ACTIVE_STATUS_TYPES.includes(updates.status)
    ) {
      batch.update(doc(DB, 'users', coachClient.coach.id), {
        // increment activeClients
        activeClients: increment(1),
      });
    }
  }

  // Update client
  batch.update(doc(DB, 'coachClients', coachClientId), updates);

  await batch.commit();

  return null;
});

export const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    resetSaveStatus: (state) => {
      state.saveStatus = FETCH_STATUS_TYPES_ENUM.IDLE;
      state.saveError = null;
    },
    setClients: (
      state,
      action: PayloadAction<{ upsertItems: CoachClient_WithID[]; removeItems: string[] }>
    ) => {
      clientsAdapter.upsertMany(state, action.payload.upsertItems);
      clientsAdapter.removeMany(state, action.payload.removeItems);
    },
    // --------------------------------------------------
    // Listener commands start
    // --------------------------------------------------
    fetchConnectionsRequestsLoading(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
    },
    fetchConnectionsRequestsSuccess(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      state.listener = action.payload;
    },
    fetchConnectionsRequestsFailure(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.error = action.payload;
      console.error(action.payload);
    },
    stopListener: (state) => {
      if (state.listener) {
        state.listener();
      }
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    // --------------------------------------------------
    reset: (state) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      // Save
      .addCase(saveClient.pending, (state) => {
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
        state.saveError = null;
      })
      .addCase(saveClient.fulfilled, (state) => {
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      })
      .addCase(saveClient.rejected, (state, action) => {
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.saveError = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // Update
      .addCase(updateClient.pending, (state) => {
        state.updateStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
        state.updateError = null;
      })
      .addCase(updateClient.fulfilled, (state) => {
        state.updateStatus = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.updateStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.updateError = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      });
  },
});

export const {
  resetSaveStatus,
  setClients,
  fetchConnectionsRequestsLoading,
  fetchConnectionsRequestsSuccess,
  fetchConnectionsRequestsFailure,
  setError,
  reset,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------
export const startClientsListener =
  (coachId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { listener } = state.clients;

    if (listener) {
      console.error('Clients listener already exists');
      return;
    }

    const userId = coachId;
    dispatch(fetchConnectionsRequestsLoading());
    try {
      const q = query(collection(DB, 'coachClients'), where('coach.id', '==', userId));
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const upsertItems: CoachClient_WithID[] = [];
          const removeItems: string[] = [];

          querySnapshot.docChanges().forEach((change) => {
            const { id } = change.doc;
            const data = change.doc.data();
            convertFirebaseDataDates(data);

            const item = { ...data, id } as CoachClient_WithID;

            if (change.type === 'added' || change.type === 'modified') {
              upsertItems.push(item);
            }
            if (change.type === 'removed') {
              removeItems.push(id);
            }
          });

          dispatch(setClients({ upsertItems, removeItems }));
        },
        (error) => {
          dispatch(setError(error.message));
        }
      );
      dispatch(fetchConnectionsRequestsSuccess(unsubscribe));
    } catch (error) {
      dispatch(fetchConnectionsRequestsFailure(error.message));
    }
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllCoachClients,
  selectById: selectCoachClientById,
  // Pass in a selector that returns the posts slice of state
} = clientsAdapter.getSelectors((state: RootState) => state.clients);

export const getClientsFetchStatus = (state: RootState) => state.clients.status;
export const getClientsFetchError = (state: RootState) => state.clients.error;
export const getClientsSaveStatus = (state: RootState) => state.clients.saveStatus;
export const getClientsSaveError = (state: RootState) => state.clients.saveError;
export const getClientsUpdateStatus = (state: RootState) => state.clients.updateStatus;
export const getClientsUpdateError = (state: RootState) => state.clients.updateError;

export const selectActiveClientsList = createSelector([selectAllCoachClients], (coachClients) =>
  coachClients
    .filter(
      (coachClient) =>
        // Where in CLIENT_ACTIVE_LIST_STATUS_TYPES
        coachClient.status === CLIENT_STATUS_ENUM.CURRENT_COACH ||
        coachClient.status === CLIENT_STATUS_ENUM.ACTIVE ||
        coachClient.status === CLIENT_STATUS_ENUM.PENDING ||
        coachClient.status === CLIENT_STATUS_ENUM.REGISTERING
    )
    .map((coachClient) => ({
      id: coachClient.client.id,
      name: `${coachClient.client.firstName} ${coachClient.client.lastName}`,
      imageUrl: coachClient.client.profilePictureUrl,
    }))
);

export const selectActiveClientsListFirstAndLast = createSelector(
  [selectAllCoachClients],
  (coachClients) =>
    coachClients
      .filter(
        (coachClient) =>
          // Where in CLIENT_ACTIVE_LIST_STATUS_TYPES
          coachClient.status === CLIENT_STATUS_ENUM.CURRENT_COACH ||
          coachClient.status === CLIENT_STATUS_ENUM.ACTIVE ||
          coachClient.status === CLIENT_STATUS_ENUM.PENDING ||
          coachClient.status === CLIENT_STATUS_ENUM.REGISTERING
      )
      .map((coachClient) => ({
        id: coachClient.client.id,
        firstName: coachClient.client.firstName,
        lastName: coachClient.client.lastName,
        profilePictureUrl: coachClient.client.profilePictureUrl,
      }))
);

export const selectNumberOfActiveClients = createSelector(
  [selectAllCoachClients],
  (coachClients) =>
    coachClients.filter(
      (coachClient) =>
        // Where in CLIENT_ACTIVE_STATUS_TYPES
        coachClient.status === CLIENT_STATUS_ENUM.ACTIVE ||
        coachClient.status === CLIENT_STATUS_ENUM.PENDING ||
        coachClient.status === CLIENT_STATUS_ENUM.REGISTERING ||
        coachClient.status === CLIENT_STATUS_ENUM.EXPIRING
    ).length
);

const selectClientId = (state: RootState, clientId: string) => clientId;

export const selectCoachClientByClientId = createSelector(
  [selectAllCoachClients, selectClientId],
  (coachClients, clientId) => coachClients.find((coachClient) => coachClient.client.id === clientId)
);

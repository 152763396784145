// @mui
import { Stack, Button, Typography, Link } from '@mui/material';

// ----------------------------------------------------------------------

export default function NavbarReport() {
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      <div>
        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
          Found an issue?
          <br /> Please report it here.
        </Typography>
      </div>

      <Link
        target="_blank"
        href="https://www.oniworkout.co.nz/how-to-guides/feedback/report-a-bug/"
      >
        <Button variant="contained">Report Issue</Button>
      </Link>
    </Stack>
  );
}

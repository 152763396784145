import { Alert } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { StripeClientSubscription } from 'src/@types/firebase';
import { ClientSubscriptionContext } from 'src/contexts/ClientSubscriptionContext';
import { FUNCTIONS } from 'src/contexts/FirebaseContext';
import useCurrentUser from './useCurrentUser';

// ----------------------------------------------------------------------

export default function useClientSubscription() {
  // If a user has failed invoices and their subscription is cancelled, they will be redirected to the payment page
  const { isSubscribed } = useCurrentUser();
  const { status, setStatus } = useContext(ClientSubscriptionContext);
  const [error, setError] = useState<string | null>(null);

  const handleCreateClientSubscription = useCallback(async () => {
    try {
      const createClientSubscription = httpsCallable<
        undefined,
        { clientSubscription: StripeClientSubscription; latestInvoice: any }
      >(FUNCTIONS, 'events-stripe-createClientSubscription');

      // If the user doesn't have a client subscription, create one
      if (!isSubscribed) {
        if (status === FETCH_STATUS_TYPES_ENUM.IDLE) {
          setStatus(FETCH_STATUS_TYPES_ENUM.LOADING);
          await createClientSubscription();
          setStatus(FETCH_STATUS_TYPES_ENUM.SUCCEEDED);
        }
      } else {
        setStatus(FETCH_STATUS_TYPES_ENUM.SUCCEEDED);
      }
    } catch (error) {
      setStatus(FETCH_STATUS_TYPES_ENUM.FAILED);
      setError(error.message);
    }
  }, [isSubscribed, status, setStatus]);

  const tryAgain = useCallback(() => {
    setStatus(FETCH_STATUS_TYPES_ENUM.IDLE);
  }, [setStatus]);

  useEffect(() => {
    handleCreateClientSubscription();
  }, [isSubscribed, status, handleCreateClientSubscription]);

  const ErrorAlert = () => (!!error ? <Alert severity="error">{error}</Alert> : null);

  return {
    idle: status === FETCH_STATUS_TYPES_ENUM.IDLE,
    loading: status === FETCH_STATUS_TYPES_ENUM.LOADING,
    success: status === FETCH_STATUS_TYPES_ENUM.SUCCEEDED,
    failed: status === FETCH_STATUS_TYPES_ENUM.FAILED,
    clientSubscriptionActive: isSubscribed,
    tryAgain,
    ErrorAlert,
  };
}

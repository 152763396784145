import { ReactNode } from 'react';

import LoadingScreen from 'src/components/LoadingScreen';
import { Navigate } from 'react-router';
import { PATH_PAGE } from 'src/routes/paths';
import useClientSubscription from 'src/hooks/useClientSubscription';

// ----------------------------------------------------------------------
type Props = {
  children: ReactNode;
};

export default function ClientSubscriptionGuard({ children }: Props) {
  const { idle, loading, success, failed, clientSubscriptionActive } = useClientSubscription();

  if (idle || loading) {
    return <LoadingScreen />;
  }

  // If the user still doesn't have a client subscription provide a screen with a button to try agian
  if ((failed || success) && !clientSubscriptionActive) {
    // pass the try again function to the page
    return <Navigate to={PATH_PAGE.accountIssues} />;
  }

  return <>{children}</>;
}
